import axios from "axios";
// ** import {api_log} from "./apilogaxios";
import { getLocalData } from "./helper";
//const BASE_URL = window._env_.APP_BASE_URL + window._env_.APP_API_VERSION;
const BASE_URL = process.env.REACT_APP_BACKEND + "/api/v2/";
// axios.defaults.baseURL = BASE_URL
//axios.defaults.withCredentials = true; // 기존에 쿠키를 사용중이어서, 이 라인 복구시 치명적 오류 발생. CORS 요청 시에 쿠키를 포함하여 보낼 수 있도록 설정
// axios.defaults.timeout = 20000; 
export default class WebServiceV2 {
  static async post(action, params) {
    //api_log({logdef: 'call_B_post', call_url: axios.defaults.baseURL + action, params : params})
    let response = await axios.post(BASE_URL+action, params);
    return response.data;
  }
  static async put(action, params) {
    //api_log({logdef: 'call_B_put', call_url: axios.defaults.baseURL + action, params : params})
    let response = await axios.put(BASE_URL+action, params);
    return response.data;
  }
  static async get(action) {
    //api_log({logdef: 'call_B_get', call_url: axios.defaults.baseURL + action})
    let response = await axios.get(action, { baseURL: BASE_URL });
    return response.data;
  }
  static async delete(action) {
    //api_log({logdef: 'call_B_delete', call_url: axios.defaults.baseURL + action})
    let response = await axios.delete(BASE_URL+action);
    return response.data;
  }
  static async patch(action, params) {
    //api_log({logdef: 'call_B_patch', call_url: axios.defaults.baseURL + action, params : params})
    let response = await axios.patch(BASE_URL+action, params);
    return response.data;
  }

  static getQueryString(params) {
    return `?${Object.entries(params)
      .map((e) => e.join("="))
      .join("&")}`;
  }
}

axios.interceptors.request.use(
  async (config) => {
    // Do something before request is sent
    // config.baseURL = BASE_URL;
    const token = await getLocalData("token");
    config.headers.common["Authorization"] = token ? "Bearer " + token : "";
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  (error) => {
    // // Any status codes that falls outside the range of 2xx cause this function to trigger
    // // Do something with response error
    const { response } = error;
    // const originalRequest = config;
    if (response.status === 401 || response.status === 404) {
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);
