import PropTypes from "prop-types";
import React, { useEffect,useCallback,useState  } from "react";
//import $ from 'jquery';
import { Link, useLocation } from "react-router-dom";
import { multilanguage } from "redux-multilanguage";
import { connect } from "react-redux";
import WebService from '../../util/webService';
import { setCategoryID } from "../../redux/actions/productActions";
import { setContent } from "../../redux/actions/contentAction";
import TopMenu from  '../../util/TopMenu.js';	// **
import { setLocalData, getLocalData } from "../../util/helper";
const NavMenu = ({ merchant, props, strings, menuWhiteClass, sidebarMenu, categories, contents, setCategoryID, setContent, currentLanguageCode }) => {
	/*
	// ** 240327 : TopMenu is only used content page link. Test finished. back again !!!
	// ** 240329 : TopMenu is only used Custom creation document.
	const onClickCategory = (item) => {
		setCategoryID(item.id)
	}
	const onClickContent = (item) => {
		setContent(item)
	}
	// */
	const [topMenu, setTopMenu] = useState([]);
	const headerMenu = useCallback( async () => {
		const getTopMenu = getLocalData("topMenu");
		const topMenuCreDt = getLocalData("topMenuCreDt");
		const targetTime = new Date(topMenuCreDt);
		const currentTime = new Date();
		
		const diffInMilliseconds = Math.abs(currentTime - targetTime); // 절대값 사용
		const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
		const diffInMinutes = Math.floor(diffInSeconds / 60);
		if(getTopMenu === null || diffInMinutes > 60 ){
			const param= {"visible":0};
			const topMenuResponse = await WebService.get(`usermenu`+WebService.getQueryString(param));
			setTopMenu(topMenuResponse.children);
			setLocalData("topMenu", JSON.stringify(topMenuResponse.children));
			setLocalData("topMenuCreDt", new Date());
		}else {
			setTopMenu(JSON.parse(getTopMenu));
		}
		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [topMenu]);

	const location = useLocation();
	const location1 = location.pathname.split("/")[1];
	useEffect(() => {
		headerMenu();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div id="mainNavi-wrap" className="hMenu">
				<div id="menu-wrap" className="menuCont">
					<div className="menuTopM">
						<Link to={process.env.PUBLIC_URL + "/"}>
							<img alt="" src="/assets/img/logo/logo.svg" />
							{/* <img alt="" src={merchant.logo.path} /> */}
						</Link>
						<button type="button" id="mNaviCloser">
							<span className="blind">Menu Close</span>
						</button>
					</div>
					<nav id="mainNavi" className="menuCore">
						<div className="depth1-wrap">
							<ul className="depth1-ul">
								<li className="all">
									<button type="button">
										<span className="stripe"><span></span></span>
										<em>{strings["Categories"]}</em>
									</button>
								</li>
								{
									topMenu?.map( ( item, key ) => {
										if ( item.top === "Y" ) {
											let menuUrl = item.url.split("/")[1];
											let clsOn = "";
											if ( location1 === 'category' ) {
												if ( item === -1 ) {
													clsOn = "on";
												}
											} else if ( location1 === menuUrl ) {
												clsOn = "on";
											}
											return (
												<li key={key} className={`mn_li1 ${clsOn}`}>
													<Link to={item.url} className="mn_a1">
														{
															currentLanguageCode && currentLanguageCode === "ko" ? (
																<span className="txt">{item.menuName}</span>
															) : currentLanguageCode === "en" ? (
																<span className="txt">{item.menuNameEn}</span>
															) : currentLanguageCode === "zh" ? (
																<span className="txt">{item.menuNameCn}</span>
															) : currentLanguageCode === "jp" ? (
																<span className="txt">{item.menuNameJp}</span>
															) : (
																<span className="txt">{item.menuName}</span>
															)
														}
													</Link>
												</li>
											)
										} else {
											return null;
										}
									})
								}
							</ul>
						</div>
					</nav>
				</div>
				<TopMenu />
			</div>
			<div className="hMenuOverlay" id="mNaviOverlay"></div>
			{/* <div className="hMobile">
				<button type="button" id="mNaviControllerBtn">
					<span className="blind txt">Menu Open</span>
					<i className="ri-menu-line ico"></i>
				</button>
			</div> */}
		</>
	);
};

NavMenu.propTypes = {
	currentLanguageCode: PropTypes.string,
	menuWhiteClass: PropTypes.string,
	sidebarMenu: PropTypes.bool,
	strings: PropTypes.object
};
const mapStateToProps = state => {
	return {
		merchant: state.merchantData.merchant
	};
};
const mapDispatchToProps = dispatch => {
	return {
		setCategoryID: (value) => {
			dispatch(setCategoryID(value));
		},
		setContent: (value) => {
			dispatch(setContent(value));
		}
	};
};

export default connect( mapStateToProps, mapDispatchToProps )( multilanguage(NavMenu) );
// export default multilanguage(NavMenu);
