const Constant = {
	ACTION: {
		STORE: "store/",
		DEFAULT: "DEFAULT/",
		CATEGORY: "category/",
		CONTENT: "content/",
		PAGES: "pages/",
		PRODUCT_GROUP: "products/group/",
		PRODUCT: "product/",
		PRODUCTS: "products/",
		CART: "customer_cart/",
		CARTS: "cart",
		PRICE: "price/",
		CUSTOMER: "customer/",
		LOGIN: "login/",
		CUSTOMERS: "customers/",
		MANUFACTURERS: "manufacturers/",
		VARIANTS: "variants/",
		CONFIG: "config/",
		COUNTRY: "country/",
		ZONES: "zones/",
		AUTH: "auth/",
		PROFILE: "profile",
		SHIPPING: "shipping/",
		SHIPPING_COUNTRY: "shipping/country",
		TOTAL: "total/",
		NEWSLETTER: "newsletter/",
		REGISTER: "register",
		CHECKOUT: "checkout",
		PASSWORD: "password/",
		CONTACT: "contact/",
		ORDERS: "customer_orders/",
		ADDRESS: "address",
		RESET: "reset/",
		REQUEST: "request/",
		SEARCH: "search/",
		AUTOCOMPLETE: "autocomplete/",
		BOXES: "boxes/",
		BANNER_TEXT: "bannerText/",
		IMAGES: "images/",
		HEADER_MESSAGE: "headerMessage/",
		AGREEMENT: "agreement/",
		PROMO: "promo/",
		UNIQUE: "unique",
		COUNTRIES: "countries",
		CODE: "code/",
		DETAIL: "detail",
		PRIVACY: "privacy",
		FAVORITE: "favorite/",
		RECOMMEND: "rec/",
		MAIL: "mail/",
		EMAIL: "email/",
		AUTHORIZATION: "authorization",
		EXTERNAL: "external/",
		VERIFICATION: "verification",
		ADDITINONAL: "additional/",
		SERVICES: "services/",
		SELECT_ALL: "select_all/",
		MULTI: "multi/",
		FIREBASE: "firebase/",
		TOKEN: "token",
		PUSH: "push",
		POPUP: "popup",
	},
};
export default Constant;