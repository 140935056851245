import { PROFILE_INFO_IN_PROFILE_API } from "../actions/profileAction";

const initialState = {
    profileApiData: null
};

const userProfileApiReducer = (state = initialState, action) => {
    if (action.type === PROFILE_INFO_IN_PROFILE_API) {
        return {
            ...state,
            ...action.payload 
        };
    }
    return state;
};

export default userProfileApiReducer;
