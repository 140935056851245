import { Code } from "@mui/icons-material";
import { SET_MERCHANT, SET_COMPANY, SET_STORE, SET_EXCHANGE, SET_EXCHANGE_CODE, UPDATE_DEFAULT_STORE } from "../actions/storeAction";

const initState = {
  merchant: "",
  company: "",
  defaultStore: "",
};

const storeReducer = (state = initState, action) => {
  if (action.type === SET_MERCHANT) {
    return {
      ...state,
      merchant: action.payload,
    };
  }
  if (action.type === SET_STORE) {
    return {
      ...state,
      defaultStore: action.payload,
    };
  }
  if (action.type === SET_COMPANY) {
    return {
      ...state,
      company: action.payload,
    };
  }
  if (action.type === SET_EXCHANGE) {
    return {
      ...state,
      country: action.payload,
    };
  }
  if (action.type === SET_EXCHANGE_CODE) {
    return {
      ...state,
      code: action.payload,
    };
  }
  
  return state;
};

export default storeReducer;
