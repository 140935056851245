import { Fragment, useEffect, useRef } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
const CategoryItemDepth2 = ({ item, onClickCategory, isVisible, strings, categorySearchId }) => {
	const localRef = useRef(null);
	useEffect(() => {
		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting || categorySearchId !== null) {
					isVisible(true); // 화면에 나타났음
					observer.unobserve(entry.target); // Observer를 해제
				}
			});
		});
		if (localRef.current) {
			observer.observe(localRef.current); // 요소 Observer에 등록
		}
		return () => {
			if (localRef.current) {
				observer.unobserve(localRef.current); // 컴포넌트가 언마운트될 때 Observer를 해제
			}
		};
	}, [isVisible, localRef]);

	return (
		<div className="d2Unit" data-parent={item.code} ref={localRef}>
			{item.children && item.children.length > 0 ? (
				<Fragment>
					<ul className="lv2" data-depth="2">
						{
							item.children.map( (submenu, index2) => submenu.visible && (
								<li key={index2} className={ submenu.children && submenu.children.length > 0 ? "has_sub" : "" } >
									<Link to={{ pathname: `/category/list`, search: `?id=${submenu.id}&name=${encodeURIComponent( `${submenu.description.description}` )}`, }} onClick={() => onClickCategory(submenu)} data-id={submenu.id} data-idx={submenu.code} >
										<div className="img">
											<span className="imgContainer">
												<img loading="lazy" src={`/images/category/${submenu.code}.png`} alt={submenu.code} onError={(e) => { e.target.src = "/assets/img/_Category/cate_noImg_G.jpg"; }} />
											</span>
										</div>
										<span className="txt">
											{submenu.description.name}
										</span>
									</Link>
									{submenu.children && submenu.children.length > 0 && (
										<button type="button" className="toggle" data-idx={submenu.code} >
											<span className="blind">Open & Close</span>
										</button>
									)}
								</li>
							)
						)}
					</ul>
					<p className="parentReqMsg">
						<span>{strings["Select Top Menu"]}</span>
					</p>
				</Fragment>
			) : (
				<Fragment>
					<p className="parentReqMsg">
						<span>{strings["Select Top Menu"]}</span>
					</p>
				</Fragment>
			)}
		</div>
	);
};
export default CategoryItemDepth2;