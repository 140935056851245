import { getMessaging, getToken, deleteToken, onMessage } from "firebase/messaging";
import Constant from "./constant";
import WebService from "./webService";
import { isMobile } from "react-device-detect";
import { sendFCMUserData } from "./MobileBridge"; 
import { initializeApp } from "firebase/app";
import { setLocalData } from "./helper";

const logTAG = "FCMToken : "

const isLocalhost = window.location.protocol === "https:" || window.location.hostname === "localhost"

let app = null
let messaging = null
let firebaseVapidKey = null
let firebaseConfig = null

const getApiKey = async () => {
    try {
        if (isLocalhost && !isMobile) {
    
            const action = "/authorization/key/firebase"
            const res = await WebService.EXT.get(action)

            if (res) {
                firebaseConfig = {
                    apiKey: res.REACT_APP_FB_APIKEY,
                    authDomain: res.REACT_APP_FB_AUTH_DOMAIN,
                    projectId: res.REACT_APP_FB_PROJECT_ID,
                    storageBucket: res.REACT_APP_FB_STORAGE_BUCKET,
                    messagingSenderId: res.REACT_APP_FB_MESSAGING_SENDER_ID,
                    appId: res.REACT_APP_FB_APP_ID,
                    measurementId: res.REACT_APP_FB_MEASUREMENT_ID
                }
                firebaseVapidKey = res.react_APP_FB_VAPID_KEY

                app = initializeApp(firebaseConfig)
                messaging = getMessaging(app)
            } else {
                console.log("FB init err in get apikey")
            }
        }
    } catch (err) {
        console.log(`FB init Fail! ERR : ${err}`)
    }
}

export const getFCMToken = async () => {
    try {
        if (!firebaseVapidKey && !firebaseConfig) {
            await getApiKey()
        }

        const currentToken = await getToken(messaging, { vapidKey: firebaseVapidKey }) 
        if (currentToken) {
            requestPermission()
            onMessage(messaging, (payload) => {
                if (Notification.permission === 'granted') {
                    new Notification(payload.notification.title, {
                        body: payload.notification.body,
                        icon: "push_ico.webp",
                        image: payload.notification.image,
                    })
                }
            });
            return currentToken
        } else {
            console.warn(logTAG, "token is not available")
            return
        }
    } catch (err) {
        // console.log(logTAG, err)
        return
    }
}

export const PostFCMToken = async (userEmail, userId) => {
    if (isMobile) {
        sendFCMUserData("LOGIN", userEmail, userId)
    } else {
        if (!firebaseVapidKey && !firebaseConfig) {
            await getApiKey()
        }

        getFCMToken().then((token)=> {
            postFCMTokenInExternalAPI(userEmail, userId, token)
        }).catch((err)=> {
            console.error("error in PostFCMToken : ", err)
        })
    }
}

export const DeleteFCMToken = async () => {
    const userEmail = localStorage.getItem("chatId")
    if (isMobile) {
        sendFCMUserData("LOGOUT", userEmail, "")
    } else {
        try {
            if (!firebaseVapidKey && !firebaseConfig) {
                await getApiKey()
            }

            const currentToken = await getToken(messaging, { vapidKey: firebaseVapidKey })
            if (currentToken) {
                DeleteFCMTokenInExtenalAPI(currentToken)
                await deleteToken(messaging)
                // console.log(logTAG, "Token delete success")
            } else {
                console.warn(logTAG, "Delete fail. No match Token")
            }
        } catch (error) {
            console.error(logTAG, `Token delete fail! ERR : ${error}`)
        }
    }
    setLocalData("chatId", "")
}

const requestPermission = async () => {
    const permission = await Notification.requestPermission();
    const currentUrl = window.location.pathname;
    if (currentUrl === "/" && permission === "denied") {
        alert("알림 권한이 없으면 채팅, 상품의 안내를 받을 수 없습니다.")
        return;
    }
}

const postFCMTokenInExternalAPI = async (userEmail, userId, FCMToken) => {
    let action = "firebase/token"

    const params = {
        id: null,
        token: FCMToken,
        customerId: userId,
        email: userEmail,
        osType: "WEB"
    }

    try {
        await WebService.EXT.post(action, params)
    } catch (error) {
        console.error("tokenWebService", error)
    }
}

const DeleteFCMTokenInExtenalAPI = async (FCMToken) => {
    let action = Constant.ACTION.FIREBASE + Constant.ACTION.TOKEN + "?token=" + FCMToken
    let response = await WebService.EXT.delete(action)
    if (response) {
        // console.log(`deletetoken is work ${JSON.stringify(response)}`)
    } else {
        // console.log(`deletetoken is no worked!`)
    }
}

