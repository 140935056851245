import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { save, load } from "redux-localstorage-simple";
import { Provider } from "react-redux";
// import { fetchProducts } from "./redux/actions/productActions";
import rootReducer, { resetReducer } from "./redux/reducers/rootReducer";
// import Cookies from 'js-cookie';
// import { createCookieMiddleware } from 'redux-cookie';
// import products from "./data/products.json";
import App from "./App";
import "./assets/scss/style.scss";
import * as serviceWorker from "./serviceWorker";
import { composeWithDevTools } from "redux-devtools-extension";
import AlertProvider from "./alert";
import { GlobalProvider } from "./util/globalContext";

const middleware = [thunk];
middleware.push(save()); // 로컬 스토리지에 상태 저장

// 스토어 생성
export const store = createStore(
  resetReducer,
  load(), // 로컬 스토리지에서 상태 로드
  composeWithDevTools(applyMiddleware(...middleware))
);
// fetch products from json file
// store.dispatch(fetchProducts(products));

ReactDOM.render(
  <GlobalProvider>
  <Provider store={store}>
    <AlertProvider>
      <App />
    </AlertProvider>
  </Provider>
  </GlobalProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
