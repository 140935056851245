import React, { createContext, useState, useEffect } from "react";
import Common from '../util/util';
import { LicenseInfo } from '@mui/x-license';

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
    const [loading, setLoading] = useState(true);

    const getMuiKey = async () => {
        try {
            const muiRes = await Common.muiKey();
            LicenseInfo.setLicenseKey(muiRes.key);
        }catch (error) {
            console.error('MUI 키 가져오기 실패:', error);
        }finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getMuiKey();
    }, []);

    return (
        <GlobalContext.Provider value={{ loading }}>
            {children}
        </GlobalContext.Provider>
    );
};

