import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState, useRef } from "react";
import Header from "../wrappers/header/Header";
import Footer from "../wrappers/footer/Footer";
import LayoutBanner1 from "../data/Layout/LayoutBanner1.json";
import LayoutBanner1Config from "../wrappers/LayoutBanner1Config/LayoutBanner1Config";
import LayoutLink1 from "../data/Layout/LayoutLink1.json";
import LayoutLink1Config from "../wrappers/LayoutLink1Config/LayoutLink1Config";
import constant from "../util/constant";
import WebService from "../util/webService";
import { multilanguage } from "redux-multilanguage";
import { connect, useSelector } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";
import { MetaTags } from "react-meta-tags";
import LanguageCurrencyChanger from "../components/header/sub-components/LanguageCurrencyChanger";
// import { GoogleTranslateWidget } from '../util/utilFnc';
// import LanguageCurrencyChanger from "../components/header/sub-components/LanguageCurrencyChanger";
const Layout = ({ children, headerContainerClass, headerTop, headerPaddingClass, headerPositionClass, currentLanguageCode, userData, dispatch, strings, }) => {
	const history = useHistory();
	const [loading, setLoading] = useState(true);
	const [isDataLoaded, setIsDataLoaded] = useState(false);
	const mutilangDataEn = useSelector((state) => state?.multilanguage?.languages?.en);
	const location = useLocation();
	const isMainPage = location.pathname === "/";

	/* ==============================
	* Main / Sub 여부구성.
	================================= */
	// ** let isMainPageFlag = null;
	const [ isMainPageFlag, setIsMainPageFlag] = useState("");
	useEffect(() => {
		setIsMainPageFlag( isMainPage ? "main" : "sub");
	}, [isMainPage]);
	useEffect(() => {
		if (mutilangDataEn) {
			setIsDataLoaded(true);
		}
	}, [mutilangDataEn]);
	/*
	if ( isMainPage === true ) {
		isMainPageFlag = "main";
	} else {
		isMainPageFlag = "sub";
	}
	// */

	/* ==============================
	*
	================================= */
	const LayoutBanner1Items = LayoutBanner1[currentLanguageCode];
	const LayoutLink1Items = LayoutLink1[currentLanguageCode];

	/* ==============================
	* Main Popup 추출.
	================================= */
	/*
	const [ popups, setPopups ] = useState([
		{ id:"popup1", visible:true, checked:false, width:"", height:"", left:"100px", top:"200px" },
		{ id:"popup2", visible:true, checked:false, width:"", height:"", left:"610px", top:"200px" }
	]);
	// */
	const [ popups, setPopups ] = useState([]);
	useEffect(() => {
		if(isMainPage) getOpeningPopup();
	}, [] );
	const getOpeningPopup = async() => {
		let action = constant.ACTION.POPUP + "?site=2";
		try {
			let response = await WebService.get( action );
			if ( response ) {
				// ** console.log( "response : ", response.data );
				let updatePopups = response.data.map( popup => ({
					...popup
					, visible : true
				}) );
				setPopups( updatePopups );
			}
		} catch (error) {
			console.log("에러:", error);
		}
	}

	/** -- Cookie 제어 */
	const setCookie = (name, value, days) => {
		let expires = '';
		if (days) {
			const date = new Date();
			date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
			expires = `; expires=${date.toUTCString()}`;
		}
		document.cookie = `${name}=${value || ""}${expires}; path=/`;
	};
	const delCookie = (name) => {
		document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
	};
	const getCookie = (name) => {
		const nameEq = name + "=";
		const cookies = document.cookie.split(';');
		for (let i = 0; i < cookies.length; i++) {
			let cookie = cookies[i].trim();
			if (cookie.indexOf(nameEq) === 0) {
				return cookie.substring(nameEq.length, cookie.length);
			}
		}
		return null;
	};

	/** -- 팝업 전체 쿠키 담기 */
	const [popupCookies, setPopupCookies] = useState([]);
	const getAllOpeningPopupCookies = () => {
		const prefix = "openingPopup_";
		const cookies = document.cookie.split(';');
		const result = [];
		cookies.forEach(cookie => {
			let trimmedCookie = cookie.trim();
			if ( trimmedCookie.startsWith(prefix) ) {
				const [ name, value ] = trimmedCookie.split('=');
				result.push({ name, value });
			}
		});
		return result;
	};
	useEffect(() => {
		const cookies = getAllOpeningPopupCookies();
		setPopupCookies( cookies );
	}, []);

	/** -- 팝업 닫기 */
	const setOpeningPopClose = (id) => {
		setPopups(popups.map(popup =>
			popup.id === id ? { ...popup, visible: false } : popup
		));
	};

	/** -- 체크박스 상태 변경 시 쿠키 설정/삭제 */
	const handleCheckboxChange = (id, checked) => {
		setPopups(popups.map(popup =>
			popup.id === id ? { ...popup, checked } : popup
		));
		if ( checked ) {
			setCookie( "openingPopup_" + id, 'true', 1 );
		} else {
			delCookie( "openingPopup_" + id );
		}
	};

	/* ==============================
	* strings 검사.
	================================= */
	/*
	if (!strings) {
		return <div>2123</div>;
	}
	// */

	/* ==============================
	* header-wrap sticky 고정 감지.
	================================= */
	const [hasScrolled, setHasScrolled] = useState(false);
	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 0) {
				setHasScrolled(true);
			} else {
				setHasScrolled(false);
			}
		};
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<Fragment>
			{
				isMainPageFlag && isMainPageFlag === "main" ? (
					<div id="openingPopupPkg">
						{
							popups.map( ( popup, index ) => {
								// Cookie 값 true일때 구분.
								const matchingCookie = popupCookies.find(cookie => cookie.name === `openingPopup_${popup.id}`);
								const isChecked = matchingCookie ? matchingCookie.value === "true" : false;
								if ( isChecked ) return null;
								return (
									popup.visible && (
										<div key={index} className="popupUnit" style={{ "left":popup.left+"px", "top":popup.top+"px" }} data-id={popup.id} data-width={popup.width} data-height={popup.height} >
											<div className="boxContents">
												<img src={popup.image} alt={popup.alt} />
											</div>
											<div className="boxController">
												<span className="chks">
													<input type="checkbox" id={`popChk-${popup.id}`} onChange={ (e) => handleCheckboxChange( popup.id, e.target.checked ) } />
													<label htmlFor={`popChk-${popup.id}`}>오늘하루 보지 않기.</label>
												</span>
												<button tpye="button" className="btns" data-id={popup.id} onClick={ () => setOpeningPopClose( popup.id ) }><span className="blind">닫기</span></button>
											</div>
										</div>
									)
								)
							}
						)}
					</div>
				) : null
			}
			<div id="wrap" className={`${hasScrolled ? "stickyActive":""}`}>
				{/* to do strings 분원 뒤에 다시 랜더링 되도록 하기 */}
				{isDataLoaded ?<Header layout={headerContainerClass} top={headerTop} headerPaddingClass={headerPaddingClass} headerPositionClass={headerPositionClass} /> : (<div>Loading...</div>) }
				<article id="container-wrap" className={isMainPageFlag}>
					<div id={`${isMainPageFlag}-container`}>
						<div className="container">{children}</div>
						<div className="wing sideL-banner">
							<LayoutBanner1Config items={LayoutBanner1Items} />
						</div>
						<div className="wing sideR-link">
							<LayoutLink1Config items={LayoutLink1Items} />
						</div>
					</div>
				</article>
				{/* to do strings 분원 뒤에 다시 랜더링 되도록 하기 */}
				{isDataLoaded ? <Footer backgroundColorClass="" spaceTopClass="" spaceBottomClass="" /> : (<div>Loading...</div>) }
			</div>
		</Fragment>
	);
};
Layout.propTypes = {
	children: PropTypes.any,
	headerContainerClass: PropTypes.string,
	headerPaddingClass: PropTypes.string,
	headerPositionClass: PropTypes.string,
	headerTop: PropTypes.string,
	dispatch: PropTypes.func,
	strings: PropTypes.object,
};
const mapStateToProps = (state) => {
	return {
		currentLanguageCode: state.multilanguage.currentLanguageCode,
		userData: state.userData.userData,
	};
};
export default connect(mapStateToProps, null)(multilanguage(Layout));