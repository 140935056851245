import React, { Component } from 'react';
import $ from 'jquery';
class TopMenuAll extends Component {
	constructor(props) {
		super(props);
		this.cateLineage = props.cateLineage || []; // 초기 cateLineage 설정
		this.isInitialized = false; // 초기화 상태를 추적하는 플래그
	}
	componentDidMount() {
		this.init();
	}
	componentDidUpdate(prevProps) {
		// ** console.log( "#0 prevProps.cateLineage : ", prevProps.cateLineage );
		// ** console.log( "#0 this.props.cateLineage : ", this.props.cateLineage );
		if ( prevProps.cateLineage !== this.props.cateLineage ) {
			this.cateLineage = this.props.cateLineage;
			this.setLineage( this.cateLineage );
		}
	}
	init() {
		if (this.isInitialized) return; // 이미 초기화된 경우 실행하지 않음
		this.rootWr = $("#root");
		this.headWr = $("#header-wrap", this.rootWr );
		this.tAllCastBtn = $(".depth1-ul li.all button", this.headWr );
		this.tAllTarget = $(".hideNav", this.headWr );
		this.hideNavCloser = $(".hideNavCloser", this.headWr );
		this.hMenu = $(".hMenu", this.headWr );
		this.depth = $(".depth", this.headWr );
		this.tAllAutoCloseFnc = null;
		this.tAllAutoCloseDelay = 1000;
		this.initCast();
		this.isInitialized = true; // 초기화 완료 상태 설정
	}
	initCast() {
		const hNav = this;

		// ** Hidden Nav 열고닫기.
		hNav.tAllCastBtn.on("click", function() {
			if ( hNav.cateMarking ) {
			} else {
				$("li", this.tAllTarget ).removeClass("active");
			}
			hNav.tAllCast( $(this) );
		});

		// ** 버튼 over시 하위 메뉴 출력.
		hNav.tAllTarget.on("mouseover focus", "a", function() {
			hNav.overCast( $(this) );
		});
		hNav.tAllTarget.on("click", "a", function() {
			hNav.close();
		});
		hNav.tAllTarget.on("click", ".toggle", function() {
			hNav.overCast( $(this) );
		});

		// ** Auto Closing.
		hNav.tAllTarget.on("mouseover focus", function() {
			clearTimeout( hNav.tAllAutoCloseFnc );
		});
		hNav.tAllTarget.on("mouseout blur", function() {
			hNav.autoClose();
		});

		// ** Mobile close button.
		hNav.hideNavCloser.on("click", function() {
			hNav.close();
		});
	}
	setLineage( lineage ) {
		const hNav = this;
		hNav.tAllCastBtn.on("click", function() {
			if ( window.location.href.indexOf("/category/") > 0 ) {
				// ** console.log( "#2 lineage : ", lineage );
				// ** console.log( "#2 카테고리 페이지" );
				let lineageTmp = lineage.slice( 1, -1 ).split("/");
				let currentDepth = lineageTmp.length;
				hNav.depth.each( function( depthIndex, depthItem ) {
					if ( depthIndex < currentDepth ) {
						let tmpParent = $("#header-wrap .depth.d" + (depthIndex+1) );
						let targetBtn = $("a[data-id='" + lineageTmp[depthIndex] + "']", tmpParent ).parent();
						targetBtn.addClass("active");
						targetBtn.siblings().removeClass("active");

						let targetUnit = targetBtn.closest(".d" + (depthIndex+1) + "Unit");
						targetUnit.addClass("on");
						targetUnit.siblings().removeClass("on");
						$("li", targetUnit.siblings() ).removeClass("active");
					} else {
						let tmpParent = $("#header-wrap .depth.d" + (depthIndex+1) );
						$("> div", tmpParent ).removeClass("on");
					}
				});
			} else {
				// ** console.log( "#2 카테고리 이외" );
				$("li", this.tAllTarget ).removeClass("active");
			}
		});
	}
	tAllCast( btn ) {
		const hNav = this;
		clearTimeout( hNav.tAllAutoCloseFnc );
		if ( btn.hasClass("on") ) {
			btn.removeClass("on");
			hNav.tAllTarget.slideUp();
			hNav.tAllTarget.removeClass("on");
		} else {
			btn.addClass("on");
			hNav.tAllTarget.slideDown();
			hNav.tAllTarget.addClass("on");
		}
	}
	overCast( btn ) {
		const hNav = this;

		// ** Currency Btn Flag.
		btn.parent().addClass("on");
		btn.parent().siblings().removeClass("on");

		// ** 현재 버튼의 정보 추출.
		let thisDep = btn.closest(".depth").index() + 1;
		let thisIdx = btn.data("idx");

		// ** 하위메뉴 표시.
		let nextKey = thisDep + 1;
		let nextTarget = $(".depth.d" + nextKey, hNav.tAllTarget );
		let nextElement = $(".d" + nextKey + "Unit", nextTarget ).filter("[data-parent='" + thisIdx + "']");
		if ( nextElement.length > 0 ) {
			nextElement.parent().removeClass("noChild");
			nextElement.addClass("on");
			nextElement.siblings().removeClass("on");
		} else {
			$("> div", nextTarget ).removeClass("on");
		}

		// ** 미선택 하위항목 초기화
		let noTargetUnit = $(".d" + (nextKey+1) + "Unit", hNav.tAllTarget );
		noTargetUnit.removeClass("on");
		noTargetUnit.parent().addClass("noChild");
	}
	autoClose() {
		const hNav = this;
		clearTimeout( hNav.tAllAutoCloseFnc );
		if ( !$("body").hasClass("tmM") ) {
			hNav.tAllAutoCloseFnc = setTimeout(
				function() {
					hNav.tAllTarget.slideUp("",
						function() {
							$(this).removeClass("on");
						}
					);
					hNav.tAllCastBtn.removeClass("on").focus();
					$("*", hNav.tAllTarget ).removeClass("on");
				}
			, hNav.tAllAutoCloseDelay );
		}
	}
	close() {
		const hNav = this;
		hNav.tAllTarget.slideUp();
		hNav.tAllTarget.removeClass("on");
		hNav.tAllCastBtn.removeClass("on");

		if ( this.hMenu.css("position") === "static" ) {			// ** PC
		} else if ( this.hMenu.css("position") === "fixed" ) {		// ** Mobile. 999px 이하
			$("body").removeClass("tmM_open");
			$("#mNaviOverlay").addClass("hide");
			setTimeout(
				function() {
					$("#mNaviOverlay").removeClass("show hide");
				}
			, 500 );
		}
	}
	render() { return <></>; }
}
export default TopMenuAll;