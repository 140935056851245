import React from "react";
import { Link } from "react-router-dom";
const CategoryItemDepth1 = ({ item, onClickCategory, categorySearchId }) => {
	// const [isActive, setIsActive] = useState(false); // 활성화 상태 관리
	// useEffect(() => {
	// 	// categorySearchId와 item.id가 같으면 활성화 상태로 설정
	// 	setIsActive(categorySearchId === item.id);
	// }, [categorySearchId, item.id]); // 의존성 배열: categorySearchId나 item.id가 변경될 때 실행
	return (
		item.visible && (
			<li className={`${item.children && item.children.length > 0 ? "has_sub" : ""}`} >
				<Link to={{ pathname: `/category/list`, search: `?id=${item.id}&name=${encodeURIComponent(`${item.description.description}`)}`, }} onClick={() => onClickCategory(item)} data-id={item.id} data-idx={item.code} >
					<span className="txt">
						{item.description.name}
					</span>
				</Link>
				{item.children && item.children.length > 0 && (
					<button type="button" className="toggle" data-id={item.id} data-idx={item.code}>
						<span className="blind">Open & Close</span>
					</button>
				)}
			</li>
		)
	);
};
export default CategoryItemDepth1;