import WebService from "../../util/webService";
import constant from "../../util/constant";
import { getLocalData } from "../../util/helper";
export const SET_MERCHANT = "SET_MERCHANT";
export const SET_COMPANY = "SET_COMPANY";
export const SET_STORE = "SET_STORE";
export const SET_EXCHANGE = "SET_EXCHANGE";
export const SET_EXCHANGE_CODE = "SET_EXCHANGE_CODE";
export const RESET_STORE = "RESET_STORE";
export const UPDATE_DEFAULT_STORE = "UPDATE_DEFAULT_STORE";



export const setMerchant = (code) => {
	return async (dispatch) => {
		const localData = JSON.parse(getLocalData("redux_localstorage_simple"));
		const defaultStore = localData?.merchantData?.defaultStore;
		const storeInfo = localData?.userData?.userData?.storeInfo;

		if (storeInfo && defaultStore !== "DEFAULT") {
			
			const updatedStoreInfo = {
				...storeInfo,
				code: defaultStore,
			  };

			if(storeInfo.code !== defaultStore) {
				dispatch({
					type: SET_MERCHANT,
					payload: updatedStoreInfo,
				});
				dispatch(setStore(defaultStore));
				return;
			}

			  dispatch({
				type: SET_MERCHANT,
				payload: storeInfo,
			  });
			dispatch(setStore(localData?.merchantData?.defaultStore));

		} else {
			let action = constant.ACTION.STORE + code;
			try {
				let response = await WebService.get(action);
				// if (response) {
				dispatch({
					type: SET_MERCHANT,
					payload: response,
				});
				dispatch(setStore(response.code));
			} catch (error) { }
		}
	};
};
export const setStore = (storeCode) => {
	return async (dispatch) => {
		dispatch({
			type: SET_STORE,
			payload: storeCode,
		});
	};
};


export const setCompany = () => {
	return async (dispatch) => {
		const localData = JSON.parse(getLocalData("redux_localstorage_simple"));
		const defaultStore = localData?.merchantData?.defaultStore;
		const storeInfo = localData?.userData?.userData?.storeInfo;

		if (storeInfo && defaultStore !== "DEFAULT") {
			const updatedStoreInfo = {
				...storeInfo,
				code: defaultStore,
			};

			if(storeInfo.code !== defaultStore) {
				dispatch({
					type: SET_COMPANY,
					payload: updatedStoreInfo,
				});
				dispatch(setStore(defaultStore));
				return;
			}
			dispatch({
				type: SET_COMPANY,
				payload: storeInfo,
			});

			dispatch(setStore(defaultStore));


		} else {
			let action = constant.ACTION.STORE + window._env_.APP_MERCHANT;
			try {
				let response = await WebService.get(action);
				// console.log(response);
				// if (response) {
				dispatch({
					type: SET_COMPANY,
					payload: response,
				});
				dispatch(setStore(response.code));
			} catch (error) { }
		}
	}

};

let debounceTimeout = null;

export const setExchange = () => {
	return async (dispatch) => {
		clearTimeout(debounceTimeout);
		debounceTimeout = setTimeout(async () => {
			let action = "exchange/rate";
			try {
				let response = await WebService.EXT.get(action);
				dispatch({
					type: SET_EXCHANGE,
					payload: response,
				});
			} catch (error) {
				console.error(error);
			}
		}, 300); // 300ms 지연 시간
	};
};

export const setExchangeCode = (code) => ({
	type: SET_EXCHANGE_CODE,
	payload: code,
});

export const resetStore = () => {
	return async (dispatch) => {
		dispatch({
			type: RESET_STORE,
		});
	};
};
